import React from 'react';

const ContactHeader = () => (
    <div>
        <div class="mt-5 mx-auto w-10/12">
            <div class="text-gray-500">
                <div class="block lg:rounded-lg lg:shadow-lg pt-14 pb-2 bg-zinc-900"
                    style={{ backdropFilter: `blur(30px)` }}>
                    <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-x-6 mb-12 cursor-pointer">
                        <div class="mb-12 lg:mb-0 text-center mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-12 h-9 mx-auto mb-5 text-yellow-700">
                                <path
                                    d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path
                                    d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>
                            <p class="font-medium text-xs lg:text-sm xl:text-base">stawood.meble@gmail.com</p>
                        </div>
                        <div class="mb-12 lg:mb-0 text-center mx-auto px-5 cursor-pointer">
                            <svg class="w-8 h-8 text-yellow-700 mb-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                            <p class="font-medium text-xs lg:text-sm xl:text-base">Barwałd Górny 257, 34-130 Kalwaria Zebrzydowska</p>
                        </div>
                        <div class="mb-6 md:mb-0 text-center mx-auto cursor-pointer">
                            <svg class="w-8 h-8 text-yellow-700 mb-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                            <p class="font-medium text-xs lg:text-sm xl:text-base">+ 48 796 432 611</p>
                        </div>
                        <div class="text-center mx-auto cursor-pointer">
                            <a target="_blank" href="https://www.instagram.com/_stawood_/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-9 mx-auto mb-5 text-yellow-700"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                                <p class="font-medium text-xs lg:text-sm xl:text-base">_stawood_</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ContactHeader;